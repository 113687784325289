import React, { Component } from "react";

class Simplecanvas extends Component {
  constructor(props) {
    super(props);
    this.canvRef = React.createRef();

    this.lastNow = 0;
  }

  componentDidMount() {
    this.RAF = requestAnimationFrame(this.tick);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.RAF);
  }

  tick = () => {
    this.now = window.performance.now() / 1000; 
    this.delta = this.now - this.lastNow;    
    this.lastNow = this.now;         
    this.draw();
    this.RAF = requestAnimationFrame(this.tick);
  };

  draw = () => {
    const now = this.now;
    const angle = now * 10;
    //const { angle,now } = this.props;
    

    let sinNow1 = Math.sin(now * 9) * 128 + 128;
    let sinNow2 = Math.sin(now * 5) * 128 + 128;
    let sinNow3 = Math.sin(now * 7) * 128 + 128;
    let r = parseInt(sinNow1);
    let g = parseInt(sinNow2);
    let b = parseInt(sinNow3);
    const barColor = `rgb(${r},${g},${b})`;
    const currFps = Math.round(1 / this.delta);

    let ctx = this.ctx;

    ctx.fillStyle = "#000";
    ctx.fillRect(0, 0, 200, 50);    
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0.02)";
    ctx.fillRect(0, 0, this.width, this.height);

    ctx.fillStyle = "#fff";
    ctx.font = "30px Arial";
    ctx.fillText("FPS:" + currFps, 10, 40);
  //  console.log(currFps);

    ctx.translate(
      this.width / 2 + (Math.sin(now * 2) * this.width) / 4,
      this.height / 2 + (Math.cos(now) * this.height) / 4
    );

    ctx.rotate((angle * Math.PI) / 180);
    ctx.fillStyle = barColor;
    ctx.fillRect(-this.width / 4, -32, this.width / 2, 64);
    ctx.restore();
    
  };

  saveContext = ctx => {
    this.ctx = ctx;
    this.width = ctx.canvas.width;
    this.height = ctx.canvas.height;
  };

  render() {
    return (
      <canvas
        ref={node => (node ? this.saveContext(node.getContext("2d")) : null)}
        width={window.innerWidth}
        height={window.innerHeight}
      />
    );
  }
}

export default Simplecanvas;
