import React, { Component } from 'react'
import Menuitem from './Menuitem'
import './Menuitem.css'

class Menulist extends Component {

    constructor(props) {
        super(props);
        this.refTab = [];
        this.lastItm = -1;
    }

    mouseEnterMenu = (e) => {
    }

    mouseLeaveMenu = (e) => {
      /*  if (this.lastItm < this.refTab.length && this.lastItem != 0)
        {
            this.refTab[1].triggerFade();
        }*/
        this.lastItm = -1;
    }

    mouseEnterMenuItem = (e, key, activate) => {
        if (activate) activate();
        if (this.lastItm === -1) return;
        let diff = this.lastItm - key;
        if (Math.abs(diff) > 1) {
            for (let i = 0; i < Math.abs(diff); i++) {
                let idx = key + i * Math.sign(diff);
                if (this.refTab[idx].triggerFade)
                    this.refTab[idx].triggerFade();
            }
        }
    }

    mouseLeaveMenuItem = (e, key, deactivate) => {
        if (deactivate) deactivate();
        //console.log(key);
        this.lastItm = key;
    }

    setRef = (r, id) => {
        this.refTab[id] = r;
    }

    render() {
        return (
            <div  className="menu" onMouseEnter={this.mouseEnterMenu} onMouseLeave={this.mouseLeaveMenu}>
                <div className="menuFiller" 
                        ref={(e) => this.setRef(e, 0)} 
                        onMouseEnter={(e)=>this.mouseEnterMenuItem(e,0, null)} 
                        onMouseLeave={(e)=>this.mouseLeaveMenuItem(e,0, null)} 
                        >
                        
                        </div>               
                {this.props.items.map((i) =>
                    <Menuitem 
                        ref={(e) => this.setRef(e, i.id)} 
                        key={i.id} itemId={i.id} title={i.title} 
                        href={i.href}
                        onMouseEnter={this.mouseEnterMenuItem} 
                        onMouseLeave={this.mouseLeaveMenuItem} 
                        />
                )}

                <div className="menuFiller" 
                        ref={(e) => this.setRef(e, this.props.items.length+1)} 
                        onMouseEnter={(e)=>this.mouseEnterMenuItem(e,this.props.items.length+1, null)} 
                        onMouseLeave={(e)=>this.mouseLeaveMenuItem(e,this.props.items.length+1, null)} 
                        >
                        
                        </div>
            </div>
            
        )
    }
}


export default Menulist;