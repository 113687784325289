import React, { Component } from 'react'

class Purecanvas extends Component {

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.width !== this.screenWidth || nextProps.height !== this.screenHeight)
        {
            this.screenWidth=nextProps.width;
            this.screenHeight=nextProps.height;
            return true;
        }
        return false;
    }

    render() {
        //console.log("Rendering Purecanvas " + this.props.width + 'x' +this.props.height);
        return (
            <canvas
                width={this.props.width}
                height={this.props.height}
                ref={node => node ? this.props.contextRef(node.getContext('2d')) : null}
            />
        )
    }
}

export default Purecanvas;