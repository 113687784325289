import React, { Component } from 'react'
import Purecanvas from './Purecanvas';

class Canvas extends Component {

    constructor(props){
        super(props);
        this.state={screenWidth:0,screenHeight:0, avgFPS : 0};
        this.lastNow = 0;
        this.fpsArr=[0];
        this.fpsArrLen = 256;
        this.fpsIdx = 0;
    }

    updateWindowDimensions = ()=>{
        try
        {
        console.log ('updateWindowDimensions');
        this.setState({screenWidth:window.innerWidth, screenHeight:window.innerHeight});
        }
        catch (ex){
            console.log(ex);
        }
    }

    componentDidMount (){
        this.updateWindowDimensions();
        window.addEventListener('resize',this.updateWindowDimensions);
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.updateWindowDimensions);
    }

    componentDidUpdate() {
        
        const { angle,now, deltaTime } = this.props;
        //const delta = now - this.lastNow;
        
        let sinNow1=Math.sin(now*9)*128+128;
        let sinNow2=Math.sin(now*5)*128+128;
        let sinNow3=Math.sin(now*7)*128+128;
        let r = parseInt(sinNow1);
        let g = parseInt(sinNow2);
        let b = parseInt(sinNow3);
        const barColor = `rgb(${r},${g},${b})`;
        const currFps = Math.round(1/deltaTime);
        //this.fpsArr[this.fpsIdx++]=currFps;
        //console.log(this.fpsIdx);
        //this.fpsIdx = this.fpsIdx % this.fpsArrLen;
        //let sum = this.fpsArr.reduce(function(a, b) { return a + b; });
        //let fps = sum / this.fpsArr.length;
        let ctx = this.ctx;
        ctx.save();
        ctx.fillStyle = 'rgba(0,0,0,0.05)'
        ctx.fillRect(0, 0, this.width, this.height);
/*
        ctx.fillStyle = '#000'
        ctx.fillRect(0, 0, 200,50);                
        ctx.fillStyle = '#fff'
        ctx.font = "30px Arial";
        ctx.fillText("FPS:" + currFps, 10, 40);
*/
        ctx.translate(this.width / 2+Math.sin(now*2)*this.width/4, this.height / 2 + Math.cos(now)*this.height/4);
        ctx.rotate((angle * Math.PI) / 180);
        ctx.fillStyle = barColor;
        ctx.fillRect(-this.width/4, -32, this.width/2, 64);
        ctx.restore();
        this.lastNow = now;
    }

    saveContext = ctx => {
        this.ctx = ctx;
        this.width = ctx.canvas.width;
        this.height = ctx.canvas.height;
    }

    render() {
        return (
            <Purecanvas contextRef={this.saveContext} width={this.state.screenWidth} height={this.state.screenHeight}/>
        )
    }
}

export default Canvas;