import React, { Component } from 'react';
import './App.css';
import Animation from './components/Animation'
import Simplecanvas from './components/Simplecanvas'
import Menulist from './components/Menulist'

const menuItems=[
  {id:1, title:"Retrobonanza invite", href: "http://avataren.org/bonanza/"},
  {id:2, title:"Prekalced FFT effect", href: "http://avataren.org/fft" },
  {id:3, title:"Software rasterizer",href:"http://avataren.org/rasterizer"},
  {id:4, title:"Software raytracer", href:"http://avataren.org/path"},
  {id:5, title:"Oldschool twister", href:"http://avataren.org/twister4"},
  {id:6, title:"Poker tournament director", href:"http://avataren.org/pokerweb"},
  {id:7, title:"Oldschool scrolltext", href:"http://avataren.org/scroll/scroller.htm?t=some%20text%20to%20scroll"},
  {id:8, title:"Julekort", href:"http://avataren.org/julekort/"},
  {id:9, title:"game prototype", href:"http://avataren.org/jstest21/"},
  {id:10, title:"Julekort", href:"http://avataren.org/jstest21/"},
  {id:11, title:"Menger sponge flythrough", href:"http://avataren.org/uno3/"},
  {id:12, title:"RPG prototype", href:"http://avataren.org/rpg"}
];

class App extends Component {

  render() {
    return (
      <div className="App">
        <header className="App-header">
        <Animation />
        <Menulist items={menuItems}   />
          
        </header>
      </div>
    );
  }
}

export default App;
