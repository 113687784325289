import React, { Component } from 'react'
import Canvas from './Canvas'
class Animation extends Component {
    constructor(props) {
        super(props);
        this.state = { angle: this.getNow()*64, now: this.getNow(), deltaTime: 0 };
        this.startTime = new Date().getTime();
    }

    componentDidMount() {
        this.RAF = requestAnimationFrame(this.animationTick);
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.RAF);
    }

    getNow = ()=>{
        if (window.performance.now) return window.performance.now()/1000.0;
        const n = (new Date().getTime() - this.startTime)/1000;
        return n;
    }

    updateAnimationState = () => {
        const now = this.getNow();
        
        this.setState(prevState => ({ 
            angle: now*64,
            now: now,
            deltaTime: now - prevState.now
        }));
        
    }
    
    animationTick = () => {
        this.updateAnimationState();
        this.RAF = requestAnimationFrame(this.animationTick);
        //setTimeout(this.animationTick,10);
    }

    render() {
        return (
            <Canvas angle={this.state.angle} now={this.state.now} deltaTime={this.state.deltaTime}/>
        )
    }
}

export default Animation;