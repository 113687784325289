import React, { Component } from 'react'
import './Menuitem.css'


class Menuitem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeClass: "menuitem menuitem-unfocus"
        };
    }

    //const activeClass=()=>'menuitem-focus';

    triggerFade = () =>{
        this.activate();
        setTimeout(() => {
            this.deactivate();    
        }, 0);
        
    }

    activate = () => {
        this.setState({
            activeClass: 'menuitem menuitem-focus'
        });
    }

    deactivate = () => {
        this.setState({
            activeClass: 'menuitem menuitem-unfocus'
        });
    }

    openLink =(href)=>{
        console.log(href);
        window.location=href;
    }

    render() {
        return (
            <div className={this.state.activeClass} 
                onMouseEnter={(e) => this.props.onMouseEnter(e, this.props.itemId, this.activate)} 
                onMouseLeave={(e) => this.props.onMouseLeave(e, this.props.itemId, this.deactivate)}
                onClick={(e)=>this.openLink(this.props.href)}
                >
                <span>{this.props.title}</span>
            </div>
        )
    }
}

export default Menuitem;

